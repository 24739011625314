(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/side-nav-resize/assets/side-nav-resize.js') >= 0) return;  svs.modules.push('/components/tipsen/side-nav-resize/assets/side-nav-resize.js');
"use strict";

const {
  useEffect
} = React;
const onResize = svs.components.tipsen.sideNavResize.onResize;
const SideNavResize = _ref => {
  let {
    children
  } = _ref;
  useEffect(() => {
    onResize();
  }, []);

  useEffect(() => {
    const handler = svs.events.subscribe('/selfserviceCart/render', () => {
      onResize();
    });
    return () => {
      svs.events.unsubscribe(handler);
    };
  }, []);
  return children;
};
setGlobal('svs.components.tipsen.sideNavResize.SideNavResize', SideNavResize);

 })(window);