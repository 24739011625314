(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/side-nav-resize/assets/prescript.js') >= 0) return;  svs.modules.push('/components/tipsen/side-nav-resize/assets/prescript.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;
const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;
const onResize = () => {
  var _document$querySelect2, _document$querySelect3, _document$querySelect4, _document$querySelect5, _document$querySelect6, _document$querySelect7, _document$querySelect8;
  let varukorgen = 0;
  if (isSelfService) {
    var _document$querySelect;
    varukorgen = ((_document$querySelect = document.querySelector('.servicecart-mini')) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.offsetHeight) || 0;
  }
  const myBetsCoupon = ((_document$querySelect2 = document.querySelector('.pg_mybets__coupon')) === null || _document$querySelect2 === void 0 ? void 0 : _document$querySelect2.offsetHeight) || 0;
  const itemsMenuHolder = ((_document$querySelect3 = document.querySelector('.tipsen-side-nav-menu')) === null || _document$querySelect3 === void 0 ? void 0 : _document$querySelect3.offsetHeight) || 0;
  const secondMenu = ((_document$querySelect4 = document.querySelector('.side-nav-statistics-menu')) === null || _document$querySelect4 === void 0 ? void 0 : _document$querySelect4.offsetHeight) || 0;
  const headerHeight = ((_document$querySelect5 = document.querySelector('.header-title')) === null || _document$querySelect5 === void 0 ? void 0 : _document$querySelect5.offsetHeight) || 0;
  const balanceBar = ((_document$querySelect6 = document.querySelector('.balance-bar-libuttons')) === null || _document$querySelect6 === void 0 ? void 0 : _document$querySelect6.offsetHeight) || 0;
  const tipsenItems = ((_document$querySelect7 = document.querySelector('.tipsen-items-menu')) === null || _document$querySelect7 === void 0 ? void 0 : _document$querySelect7.offsetHeight) || 0;
  const betslipHeight = ((_document$querySelect8 = document.querySelector('.pg_tipsen_betslip')) === null || _document$querySelect8 === void 0 ? void 0 : _document$querySelect8.offsetHeight) || 0;
  const div = document.querySelector('.tipsen-side-nav-overflow');
  let margin = 45;
  if (myBetsCoupon) {
    margin = 85;
  }
  const contentHeight = window.innerHeight - (headerHeight + balanceBar + tipsenItems + betslipHeight + itemsMenuHolder + secondMenu + varukorgen + margin);
  const realContentHeight = Math.floor(contentHeight / window.innerHeight * 100);
  if (div) {
    div.style.maxHeight = "".concat(realContentHeight, "vh");
  }
};
setGlobal('svs.components.tipsen.sideNavResize.onResize', onResize);

 })(window);